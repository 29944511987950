import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Spin } from 'antd';

import { Provider as AuthProvider } from './context/authContext';
import { Provider as PropertyProvider } from './context/propertyContext';

import PrivateRoute from './helpers/PrivateRoute';

const Login = lazy(() => import('./pages/login'));
const Register = lazy(() => import('./pages/register'));
const Property = lazy(() => import('./pages/property'));
const Apartment = lazy(() => import('./pages/apartment'));
const Feed = lazy(() => import('./pages/feed'));
const Profile = lazy(() => import('./pages/profile'));

function App() {
  const styles = {
    margin: '20px 0',
    height: '100vh',
    padding: '20% 50%',
    textAlign: 'center',
    borderRadius: '4px',
  };

  const Spinner = () => {
    return (
      <div style={styles}>
        <Spin size='large' />
      </div>
    );
  };

  return (
    <AuthProvider>
      <PropertyProvider>
        <Router>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route exact path='/' component={Feed} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/register' component={Register} />
              <PrivateRoute exact path='/property' component={Property} />
              <Route exact path='/feed' component={Feed} />
              <PrivateRoute exact path='/profile' component={Profile} />
              <Route exact path='/apartment/:slug' component={Apartment} />
            </Switch>
          </Suspense>
        </Router>
      </PropertyProvider>
    </AuthProvider>
  );
}

export default App;
