import createDataContext from '../helpers/createContext';
// import * as RootNavigation from '../helpers/navigationRefs';

// import AsyncStorage from '@react-native-async-storage/async-storage';
import { setIsSending, setIsLoading } from '../helpers/utils';
import apiRoute from '../api/apiUrls';
import {
  _UPLOAD_PROPERTY,
  _GET_PROPERTY,
  _GET_ALL_PROPERTIES,
  _OWN_PROPERTY,
  _SHOW_INTEREST,
  _SET_RESPONSE,
  _CLEAR_RESPONSE,
  _IS_SENDING,
  _IS_LOADING,
} from '../helpers/types';

const propertyReducer = (state, action) => {
  switch (action.type) {
    case _UPLOAD_PROPERTY:
      return {
        ...state,
      };

    case _GET_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };

    case _GET_ALL_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
      };

    case _OWN_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };

    case _SET_RESPONSE:
      return {
        ...state,
        response: {
          status: action.status,
          kind: action.kind,
          message: action.payload,
          actionType: action.actionType,
        },
      };

    case _CLEAR_RESPONSE:
      return { ...state, response: false };

    case _IS_SENDING:
      return { ...state, isSending: action.payload || false };

    case _IS_LOADING:
      return { ...state, isLoading: action.payload || false };

    default:
      return state;
  }
};

const UPLOAD_PROPERTY =
  (dispatch) =>
  async ({
    type,
    slots,
    price,
    deadline,
    description,
    properties,
    imageCover,
    images,
    state,
    area,
    address,
    bedrooms,
  }) => {
    try {
      dispatch({ type: _CLEAR_RESPONSE });
      setIsSending(dispatch, true);

      // console.log(
      //   `type` + type,
      //   `slots` + slots,
      //   `price` + price,
      //   'description' + description,
      //   'properties' + properties,
      //   'imageCover',
      //   imageCover,
      //   'deadline' + deadline,
      //   'images' + images,
      //   'state' + state,
      //   'area' + area,
      //   'address' + address,
      //   'bedrooms'+ inputBedrooms
      // );

      if (
        type === '' ||
        slots === 0 ||
        price === 0 ||
        description === '' ||
        state === '' ||
        area === '' ||
        address === ''
      ) {
        dispatch({
          type: _SET_RESPONSE,
          status: false,
          actionType: _UPLOAD_PROPERTY,
          kind: 'error',
          payload: 'All fields are required!',
        });
        return setIsSending(dispatch, false);
      }

      // Validate Image
      if (typeof images === 'object' || typeof imageCover === 'object') {
        if (
          images.type !== 'image/png' &&
          images.type !== 'image/jpeg' &&
          images.type !== 'image/jpg' &&
          imageCover.type !== 'image/png' &&
          imageCover.type !== 'image/jpeg' &&
          imageCover.type !== 'image/jpg'
        ) {
          setIsSending(dispatch, false);
          return dispatch({
            type: _SET_RESPONSE,
            actionType: _UPLOAD_PROPERTY,
            status: false,
            kind: 'error',
            payload: 'File must be an image!',
          });
        }
      } else {
        setIsSending(dispatch, false);
        return dispatch({
          type: _SET_RESPONSE,
          status: false,
          kind: 'error',
          payload: 'Property Image is required!',
        });
      }

      const data = new FormData();

      data.append('type', type);
      data.append('price', price);
      data.append('deadline', deadline);
      data.append('slots', slots);
      data.append('description', description);
      data.append('properties', properties);
      data.append('state', state);
      data.append('area', area);
      data.append('address', address);
      data.append('imageCover', imageCover);
      for (const key of Object.keys(images)) {
        data.append('images', images[key]);
      }
      for (const key of Object.keys(bedrooms)) {
        const bed =
          bedrooms[key].price +
          ',' +
          bedrooms[key].type +
          ',' +
          bedrooms[key].no;
        data.append('bedroom', bed);
      }
      console.log(data);

      const res = await apiRoute.post('/item/new', data);

      // console.log(res.status);

      dispatch({
        type: _SET_RESPONSE,
        status: true,
        actionType: _UPLOAD_PROPERTY,
        kind: 'success',
        payload: res.data.data.name + 'uploaded successfully!',
      });
      return setIsSending(dispatch, false);
    } catch (error) {
      console.log(error.response);

      if (error.response) {
        const errorMsg = error.response.data.message;

        dispatch({
          type: _SET_RESPONSE,
          status: false,
          kind: 'error',
          payload: errorMsg,
        });
        return setIsSending(dispatch, false);
      }
      return setIsSending(dispatch, false);
    }
  };

const GET_ALL_PROPERTIES = (dispatch) => async () => {
  try {
    dispatch({ type: _CLEAR_RESPONSE });
    setIsLoading(dispatch, true);

    const res = await apiRoute.get(`/item`);

    dispatch({
      type: _GET_ALL_PROPERTIES,
      payload: res.data.data,
    });

    // console.log(res.data, res.status);

    return setIsLoading(dispatch, false);
  } catch (error) {
    //

    if (error.response) {
      console.log(error.response);
      const errorMsg = error.response.data.message;

      dispatch({
        type: _SET_RESPONSE,
        actionType: _GET_PROPERTY,
        status: false,
        kind: 'error',
        payload: errorMsg,
      });
      return setIsSending(dispatch, false);
    }
  }
};

const GET_PROPERTY =
  (dispatch) =>
  async ({ slug }) => {
    try {
      dispatch({ type: _CLEAR_RESPONSE });
      setIsLoading(dispatch, true);

      const res = await apiRoute.get(`/item/${slug}`);

      dispatch({
        type: _GET_PROPERTY,
        payload: res.data.data,
      });

      // console.log(res.data, res.status);

      return setIsLoading(dispatch, false);
    } catch (error) {
      //

      console.log(error.response);
      if (error.response) {
        const errorMsg = error.response.data.message;

        dispatch({
          type: _SET_RESPONSE,
          actionType: _GET_PROPERTY,
          status: false,
          kind: 'error',
          payload: errorMsg,
        });
        return setIsSending(dispatch, false);
      }
    }
  };

const OWN_PROPERTY =
  (dispatch) =>
  async ({ slug, amount, reference, status, type, monthsPaidFor }) => {
    try {
      dispatch({ type: _CLEAR_RESPONSE });
      setIsSending(dispatch, true);

      const res = await apiRoute.patch(`/item/pay/${slug}`, {
        amount,
        reference,
        success: status,
        type,
        monthsPaidFor,
      });

      dispatch({
        type: _OWN_PROPERTY,
        payload: res.data.data,
      });

      dispatch({
        type: _SET_RESPONSE,
        status: true,
        actionType: _OWN_PROPERTY,
        kind: 'success',
        payload: 'Payment successful!',
      });
      return setIsSending(dispatch, false);
    } catch (error) {
      //

      console.log(error.response);
      if (error.response) {
        const errorMsg = error.response.data.message;

        dispatch({
          type: _SET_RESPONSE,
          actionType: _OWN_PROPERTY,
          status: false,
          kind: 'error',
          payload: errorMsg,
        });
        return setIsSending(dispatch, false);
      }
    }
  };

const SHOW_INTEREST =
  (dispatch) =>
  async ({ name, email, channel, employment, slug, gender, description }) => {
    dispatch({ type: _CLEAR_RESPONSE });
    setIsSending(dispatch, true);
    try {
      if (name === '' || email === '' || channel === '' || employment === '') {
        dispatch({
          type: _SET_RESPONSE,
          status: false,
          actionType: _SHOW_INTEREST,
          kind: 'error',
          payload: 'All fields are required!',
        });
        return setIsSending(dispatch, false);
      }

      dispatch({ type: _CLEAR_RESPONSE });
      setIsSending(dispatch, true);

      const res = await apiRoute.patch(`/item/interest/${slug}`, {
        name,
        email,
        channel,
        employment,
        gender,
        description,
        slug,
      });

      dispatch({
        type: _SHOW_INTEREST,
        payload: res.data.data,
      });

      dispatch({
        type: _SET_RESPONSE,
        status: true,
        actionType: _SHOW_INTEREST,
        kind: 'success',
        payload: 'Thank you!',
      });
      return setIsSending(dispatch, false);
    } catch (error) {
      //

      console.log(error.response);
      if (error.response) {
        const errorMsg = error.response.data.message;

        dispatch({
          type: _SET_RESPONSE,
          actionType: _SHOW_INTEREST,
          status: false,
          kind: 'error',
          payload: errorMsg,
        });
        return setIsSending(dispatch, false);
      }
    }
  };

const clearResponse = (dispatch) => () => {
  dispatch({ type: _CLEAR_RESPONSE });
};

export const { Provider, Context } = createDataContext(
  propertyReducer,
  {
    UPLOAD_PROPERTY,
    GET_ALL_PROPERTIES,
    GET_PROPERTY,
    OWN_PROPERTY,
    SHOW_INTEREST,
    clearResponse,
  },
  {
    properties: [],
    property: {},
    isSending: false,
    isLoading: false,
    response: null,
  },
);
