import { _IS_SENDING, _IS_LOADING } from '../helpers/types';

export const setIsSending = (dispatch, isSending) => {
  dispatch({
    type: _IS_SENDING,
    payload: isSending,
  });
};

export const setIsLoading = (dispatch, isLoading) => {
  dispatch({
    type: _IS_LOADING,
    payload: isLoading,
  });
};

export const checkIfHtml = (str) => {
  return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(
    str,
  );
};
