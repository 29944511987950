import axios from 'axios';

const instance = axios.create({
  // ? 'http://localhost:8000/api/v1'
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/api/v1'
      : 'https://rumiafrica.herokuapp.com/api/v1',
  // baseURL: 'https://randomuser.me/api/',
  headers: {
    Accept: 'application/json',
    // 'Content-Type': 'multipart/form-data; ',
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('token');
    // config.headers

    if (token) {
      console.log('token present');
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
