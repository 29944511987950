// Helpers
export const _CLEAR_RESPONSE = 'clear_response';
export const _SET_RESPONSE = 'set_response';
export const _IS_SENDING = 'is_sending';
export const _IS_LOADING = 'is_loading';

// AUTH
export const _LOGIN = 'login';
export const _LOGOUT = 'logout';
export const _REGISTER = 'register';
export const _FORGOTPASSWORD = 'forgot-password';
export const _RESETPASSWORD = 'reset-password';
export const _GET_USER = 'get_user';
export const _UPDATE_USER = 'update_user';

// TOURNAMENT
export const _UPLOAD_PROPERTY = 'upload_property';
export const _GET_PROPERTY = 'get_property';
export const _GET_ALL_PROPERTIES = 'get_all_properties';
export const _OWN_PROPERTY = 'own_property';
export const _SHOW_INTEREST = 'show_interest';
