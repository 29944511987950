import createDataContext from '../helpers/createContext';
// import * as RootNavigation from '../helpers/navigationRefs';

// import AsyncStorage from '@react-native-async-storage/async-storage';
import { setIsSending } from '../helpers/utils';
import apiRoute from '../api/apiUrls';
import {
  _REGISTER,
  _LOGIN,
  _SET_RESPONSE,
  _CLEAR_RESPONSE,
  _IS_SENDING,
  // _FORGOTPASSWORD,
  // _RESETPASSWORD,
  _GET_USER,
  // _UPDATE_USER,
  // _GET_ALL_USERS,
  _LOGOUT,
  _UPDATE_USER,
} from '../helpers/types';
import sanitizePhoneNumber from '../helpers/sanitizePhone';

const authReducer = (state, action) => {
  switch (action.type) {
    case _REGISTER:
      return {
        ...state,
        token: action.payload,
        username: action.username,
        isLoggedIn: action.isLoggedIn,
        email: action.email,
      };

    case _LOGIN:
      return {
        ...state,
        email: action.payload.email,
        token: action.payload.token,
        username: action.payload.username,
        isLoggedIn: action.payload.isLoggedIn,
        userID: action.payload.userID,
        isAdmin: action.payload.isAdmin,
      };

    case _UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };

    case _GET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case _LOGOUT:
      return {
        token: null,
      };

    case _SET_RESPONSE:
      return {
        ...state,
        response: {
          status: action.status,
          kind: action.kind,
          message: action.payload,
          actionType: action.actionType,
        },
      };

    case _CLEAR_RESPONSE:
      return { ...state, response: false };

    case _IS_SENDING:
      return { ...state, isSending: action.payload || false };

    default:
      return state;
  }
};

const UPDATE_USER =
  (dispatch) =>
  async ({ name, phone, dob, photo, occupation }) => {
    try {
      dispatch({ type: _CLEAR_RESPONSE });
      setIsSending(dispatch, true);

      // if (name === '' || phone === '') {
      //   dispatch({
      //     type: _SET_RESPONSE,
      //     status: false,
      //     actionType: _UPDATE_USER,
      //     kind: 'error',
      //     payload: 'All fields are required!',
      //   });
      //   return setIsSending(dispatch, false);
      // }

      if (typeof photo === 'object') {
        if (
          photo.type !== 'image/png' &&
          photo.type !== 'image/jpeg' &&
          photo.type !== 'image/jpg'
        ) {
          setIsSending(dispatch, false);
          return dispatch({
            type: _SET_RESPONSE,
            actionType: _UPDATE_USER,
            status: false,
            kind: 'error',
            payload: 'File must be an image!',
          });
        }
      }

      let tel;
      if (phone) {
        tel = sanitizePhoneNumber(phone);

        if (!tel) {
          dispatch({
            type: _SET_RESPONSE,
            status: false,
            actionType: _UPDATE_USER,
            kind: 'error',
            payload: 'Phone Number is not valid',
          });
          return setIsSending(dispatch, false);
        }
      }

      const data = new FormData();
      if (name) data.append('name', name);
      if (phone) data.append('phone', phone);
      if (dob) data.append('dob', dob);
      if (occupation) data.append('occupation', occupation);
      if (photo) data.append('photo', photo);

      let res = await apiRoute.patch('/user/update', data);

      if (res.data.status.trim() === 'success') {
        dispatch({
          type: _UPDATE_USER,
          actionType: _UPDATE_USER,
          payload: {
            user: res.data.user,
          },
        });
      }

      setIsSending(dispatch, false);

      return dispatch({
        type: _SET_RESPONSE,
        status: true,
        actionType: _UPDATE_USER,
        kind: 'success',
        payload: 'Profile updated successfully',
      });
    } catch (error) {
      if (error.response) {
        const errorMsg = error.response.data.message;

        dispatch({
          type: _SET_RESPONSE,
          actionType: _UPDATE_USER,
          status: false,
          kind: 'error',
          payload: errorMsg,
        });
        return setIsSending(dispatch, false);
      }
    }
  };

const REGISTER =
  (dispatch) =>
  async ({ username, email, phone, name, gender, password, terms }) => {
    try {
      dispatch({ type: _CLEAR_RESPONSE });
      setIsSending(dispatch, true);

      if (
        username === undefined ||
        name === undefined ||
        password === undefined ||
        phone === undefined ||
        email === undefined ||
        terms === false ||
        gender === false ||
        name === '' ||
        username === '' ||
        gender === '' ||
        password === '' ||
        phone === '' ||
        email === '' ||
        terms === ''
      ) {
        dispatch({
          type: _SET_RESPONSE,
          actionType: _REGISTER,
          status: false,
          kind: 'error',
          payload: 'All fields are required!',
        });
        return setIsSending(dispatch, false);
      }

      const res = await apiRoute.post('/auth/register', {
        name,
        username,
        email,
        phone,
        gender,
        password,
      });

      dispatch({
        actionType: _REGISTER,
        type: _SET_RESPONSE,
        status: true,
        kind: 'success',
        payload: 'Welcome to Rumi',
      });
      return setIsSending(dispatch, false);
    } catch (error) {
      if (error.response) {
        const errorMsg = error.response.data.message;

        dispatch({
          actionType: _REGISTER,
          type: _SET_RESPONSE,
          status: false,
          kind: 'error',
          payload: errorMsg,
        });
        return setIsSending(dispatch, false);
      }
    }
  };

const LOGIN =
  (dispatch) =>
  async ({ passkey, password }) => {
    try {
      dispatch({ type: _CLEAR_RESPONSE });
      setIsSending(dispatch, true);

      if (passkey === '' || password === '') {
        dispatch({
          type: _SET_RESPONSE,
          status: false,
          kind: 'error',
          payload: 'All fields are required!',
        });
        return setIsSending(dispatch, false);
      }

      let res;

      if (passkey.includes('.')) {
        res = await apiRoute.post('/auth/login', {
          email: passkey,
          password,
        });
      } else {
        res = await apiRoute.post('/auth/login', {
          username: passkey,
          password,
        });
      }

      if (res.data.status.trim() === 'success') {
        localStorage.setItem('token', res.data.token);
        // localStorage.setItem('', res.data.token);
        localStorage.setItem('username', res.data.user.username);
        localStorage.setItem('email', res.data.user.email);
        localStorage.setItem('isLoggedIn', true);

        dispatch({
          type: _LOGIN,
          actionType: _LOGIN,
          payload: {
            username: res.data.user.userName,
            email: res.data.user.email,
            isLoggedIn: true,
            token: res.data.token,
            userID: res.data.user._id,
          },
        });

        // if (type === 'user') {
        //   await GET_USER(dispatch)();
        // }
      }

      setIsSending(dispatch, false);

      return dispatch({
        type: _SET_RESPONSE,
        status: true,
        kind: 'success',
        payload: 'Logging in...',
      });
    } catch (error) {
      if (error.response) {
        const errorMsg = error.response.data.message;

        dispatch({
          type: _SET_RESPONSE,
          status: false,
          kind: 'error',
          payload: errorMsg,
        });
        return setIsSending(dispatch, false);
      }
    }
  };

const LOGOUT = (dispatch) => async () => {
  dispatch({ type: _LOGOUT });

  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('email');
  localStorage.removeItem('isLoggedIn');
};

const GET_USER = (dispatch) => async () => {
  try {
    dispatch({ type: _CLEAR_RESPONSE });
    setIsSending(dispatch, true);

    const res = await apiRoute.get('/auth/detail');

    dispatch({
      type: _GET_USER,
      payload: res.data.user,
    });

    return setIsSending(dispatch, false);
  } catch (error) {
    if (error.response) {
      let errorMsg = error.response.data.message;

      // JsonWebTokenError

      if (
        error.response.data.error &&
        error.response.data.error.name === 'JsonWebTokenError'
      ) {
        errorMsg = `You're not logged in`;
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('email');
        localStorage.removeItem('isLoggedIn');
      }

      dispatch({
        type: _SET_RESPONSE,
        status: false,
        kind: 'error',
        payload: errorMsg,
      });
      return setIsSending(dispatch, false);
    }
    return setIsSending(dispatch, false);
  }
};
const clearResponse = (dispatch) => () => {
  dispatch({ type: _CLEAR_RESPONSE });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    REGISTER,
    // FORGOTPASSWORD,
    UPDATE_USER,
    LOGIN,
    LOGOUT,
    GET_USER,
    clearResponse,
  },
  {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    isLoggedIn: localStorage.getItem('isLoggedIn')
      ? Boolean(localStorage.getItem('isLoggedIn').trim())
      : false,
    isSending: false,
  },
);
