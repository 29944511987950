const COUNTRY_CODE = '234';

const verifyPhoneNumber = (phone) => {
  return /^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g.test(
    String(phone),
  );
};

const sanitizePhoneNumber = (phone) => {
  phone = String(phone);
  if (!verifyPhoneNumber(phone)) {
    return false;
  }
  if (phone.startsWith('0') || phone.startsWith('+')) {
    phone = phone.substring(1);
  }
  if (phone.startsWith(COUNTRY_CODE)) {
    return phone;
  }
  return `${COUNTRY_CODE}${phone}`;
};

export default sanitizePhoneNumber;
