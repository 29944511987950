import React, { Component, useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { Context as AuthContext } from '../context/AuthContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const { state, GET_USER } = useContext(AuthContext);
  // const { isLoggedIn, isLoading } = state;

  // useEffect(() => {
  //   GET_USER();
  // }, []);

  // const token = async () => await localStorage.getItem('token');
  // const username = async () => await localStorage.getItem('username');
  const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');

  return (
    <Route
      {...rest}
      render={(props) =>
        token && username ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
